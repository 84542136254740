import styled from 'styled-components'

export const Container = styled.div`
  max-width: 500px;
  margin: 20px auto 0;
`

export const Title = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  margin-bottom: 20px;
`

export const Text = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  color: ${({ theme: { color } }) => color.text};
  margin-bottom: 15px;
  line-height: 1.7;
  text-align: justify;
`
