/* eslint-disable max-len */
import React from 'react'

import { Container, Title, Text } from './styles'

const About = () => (
  <Container>
    <Title>À propos d&apos;Old Tree</Title>
    <Text>
      Le collectif Old Tree a pour objectif l’accompagnement de groupes de musique et artistes en proposant la fabrication de matériel de musique sur mesure et l&apos;impression de merchandising, répondant à leurs besoins en proposant des solutions adaptées aux artistes amateur.rices comme professionnel.les.
    </Text>
    <Text>
      Old Tree Music Gear a été créé par Yann en 2014 lorsqu’il démarre la fabrication de ses premiers baffles dans l’atelier de son père. Rapidement, il monte son propre atelier et depuis ne cesse de développer des produits pour répondre au mieux aux besoins des musicien.nes, sur scène comme en studio.
    </Text>
    <Text>
      En 2016, Thomas rejoint Yann et crée Old Tree Recording Studio. Il quitte le collectif fin 2020 pour monter son studio indépendamment d’Old Tree.
    </Text>
    <Text>
      Début 2019, Adrien crée Old Tree Prints et rejoint le collectif. Après des années à sérigraphier sous le nom « Imprimerie la forge » , aujourd&apos;hui Old Tree Prints s&apos;efforce de proposer des impressions de qualité et adaptées à tous les budgets, avec des encres et produits respectueux de l’environnement.
    </Text>
    <Text>
      Fin 2020, le collectif lance sa boutique en ligne pour pouvoir proposer ses propres designs et ceux d’artistes cher.es à son cœur, et bien plus !
    </Text>
  </Container>
)

export default About
