import React from 'react'
import Layout from '../Layout'
import SEO from '../SEO'

import About from '../components/About'

const Shop = () => (
  <Layout>
    <SEO
      title="A propos"
      description="A propos de Old Tree"
      keywords={['Old Tree', 'about', 'à propos']}
    />
    <About />
  </Layout>
)

export default Shop
